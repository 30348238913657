/******************************************************************************/
/*                                RESETS                                      */
/******************************************************************************/
body {
  margin: 0;
}

html,
body {
  height: 100%;
  color: white;
  font-family: 'Open Sans', serif, Arial;
}

/* You can add global styles to this file, and also import other style files */

@import '~@angular/material/theming';

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

/* You can add global styles to this file, and also import other style files */

// SEE https://gitlab.amwell.systems/bhrugu.desai/hello-aw-design-system/-/blob/master/README.md
/*****************************************************************************/
/* === A. Design System Common === */
@import '~@aw-hospital/aw-styles/stylesheets/modules/common';
// @import '~@aw-hospital/aw-styles/stylesheets/modules/themes_core';
// @import "~@aw-hospital/aw-styles/stylesheets/themes/theming";

/* === B. Design System Themes === */
/* load both Dark and Light so it can be applied or changed programatically */
@import '~@aw-hospital/aw-styles/stylesheets/themes/theme-dark/common';
@import '~@aw-hospital/aw-styles/stylesheets/themes/theme-light/common';
/* activate theme */
@import '~@aw-hospital/aw-styles/stylesheets/modules/base';
/*
   Default theme is (optional and fallback style) & applied when dark or light theme is not mentioned
   @import '~@aw-hospital/aw-styles/stylesheets/themes/theme-default/common.scss';
*/

/* === C. Design System Components === */
/* aw-button */

@import "assets/styles/service-line-client";


// all component specific styles will go here in **Section C**
/*****************************************************************************/

// @angular/material is only used for overlays, so import the minimum viable styling here from @angular/cdk
@import '~@angular/cdk/overlay-prebuilt.css';

// default fonts
@import 'https://fonts.googleapis.com/icon?family=Material+Icons';
